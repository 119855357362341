@import './variables';

@mixin mobile {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin mobile-sm {
  @media (min-width: #{$mobile-width}) and (max-width: #{$mobile-sm-width }) {
    @content;
  }
}
@mixin mobile-md {
  @media (min-width: #{$mobile-sm-width}) and (max-width: #{$mobile-md-width}) {
    @content;
  }
}
@mixin mobile-lg {
  @media (min-width: #{$mobile-md-width}) and (max-width: #{$mobile-lg-width }) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin mw-margin {
  max-width: 1440px;
  margin: auto;
}
@mixin mainlayout {
  max-width: 1440px !important;
  margin: auto !important;
  width: 100% !important;

  @include mobile {
    width: 100% !important;
  }
}
@mixin main-mpadding {
  padding: 10px 60px;
  @include mobile() {
    padding: 10px 16px;
  }
}
@mixin text-ellepsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@mixin center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@mixin center-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@mixin oval-shadow {
  box-shadow: 0px 4px 34px rgba(156, 158, 188, 0.3);
  border-radius: 48px;
}
//   @mixin @keyframes shine {
//   0% {
//     background-position: right;
//   }
//   100% {
//     background-position: left;
//   }
// }
@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}
