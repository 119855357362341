@import '../mixins';

.videoPlayerContainer {
  width: 100vw;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  @include center;
  &_cross {
    position: absolute;
    right: 150px;
    top: 50px;
    cursor: pointer;
    padding: 8px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    @include center;
    background-color: #fff;
    @include mobile {
      top: 50px;
      right: 10px;
    }
    svg {
      color: $--primary;
      height: 25px;
      width: 25px;
      font-size: 1.5rem;
    }
  }
  &_video {
    width: 1060px;
    height: 600px;
    border-radius: 20px;
    overflow: hidden;
  }
}
