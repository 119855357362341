$--white: #fff;
$mobile-width: 200px;
$mobile-sm-width: 320px;
$mobile-md-width: 375px;
$mobile-lg-width: 435px;
$tablet-width: 768px;
$desktop-width: 1026px;

//colors
$--primary: var(--primary);
$--primary-medium: var(--primary-medium);

$--primary-light: var(--primary-light);
$--main-border: var(--card-main);
$--main-text: var(--main-text);

$--sec-text: var(--sec-text);

$--main-bg: var(--main-bg);
$--sec-bg: var(--sec-bg);
$--dark-bg: var(--dark-bg);
$--card-green: var(--card-green);
$--card-main: var(--card-main);
$--card-sec: var(--card-sec);

$--stock-green: var(--stock-green);
$--light-green: var(--light-green);
$--light-red: var(--light-red);

$--border-main: var(--border-main);

$--secondary-accent: var(--secondary-accent);
$suraj: #f1f1f1;
